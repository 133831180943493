@use "../../vars/colors" as *;

div:has(> .bgModalGrey) {
  background-color: $color_modalBg;
  color: $color_secondary;
  border-radius: 15px;
}

.bgModal {
  background-color: rgb(128, 128, 128, 0.9);
  .bgModalGrey {
    .messageIcon {
      font-size: 3.2rem !important;
      color: $color_main;
    }
    h4{
      margin-top: 25px;
      margin-bottom: 15px;
      font-weight: 800;
      font-size: 0.9rem;
    }
    .backBtn {
      background-color: $color_main;
      color: white;
      border-radius: 10px;
      font-weight: 700;
      font-size: 0.8rem;
      padding: 5px;
      min-width: 200px;
    }
  }
}

@use "../../../vars/colors" as *;

.cashierHome {
  min-height: 100vh;
  background-color: #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
  .cashierMain {
    min-height: calc(100vh - 300px);
    min-width: 100%;
    .cashierImg {
      background-color: $color_secondary;
      min-width: 100px;
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
      border-radius: 50%;
      img {
        width: 70px;
        height: auto;
      }
    }

    .cashierTitle {
      h4 {
        font-weight: 800 !important;
        color: #000000;
      }
      p {
        font-weight: 400;
        color: #2f2f2f;
      }
    }
  }
  .reportBtn {
    background-color: whitesmoke;
    color: $color_main;
    font-weight: 800;
    border: 4px solid $color_main;
    &:hover {
      background-color: $color_main;
      color: whitesmoke;
    }
  }
  .redeemBtn {
    background-color: $color_main;
    text-align: center;
    border: 3px solid whitesmoke;
    border-radius: 15px;
    min-height: 180px;
    cursor: pointer;
    &:hover {
      background-color: $color_secondary;
      transition: all 0.1s;
    }
    img {
      max-width: 100px;
      min-width: 40px;
      height: auto;
    }
    p {
      font-weight: 700;
      font-size: 1.2rem;
      color: whitesmoke;
    }
  }
  .reportBtn {
    background-color: #ffffff;
    text-align: center;
    border: 3px solid $color_main;
    border-radius: 15px;
    min-height: 180px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > svg {
      font-size: 3.5rem;
    }
    &:hover {
      background-color: $color_main;
      transition: all 0.1s;
      p {
        color: #ffffff;
      }
    }
    p {
      font-weight: 700;
      font-size: 1.2rem;
      color: $color_main;
    }
  }
  .logoutBtn {
    background-color: $color_main;
    border-radius: 5px;
    font-weight: 700;
    font-size: 1rem;
  }
  .changeBtn {
    background-color: $color_main;
    border-radius: 5px;
    font-weight: 700;
    font-size: 1rem;
  }
}

@use "../../../../../vars/colors" as *;

div:has(> .bgModalGrey) {
  background-color: $color_modalBg;
  border-radius: 15px;
}
.bgModal {
  background-color: rgb(0, 0, 0, 0.8);
  .bgModalGrey {
    h4 {
      font-size: 1.4rem !important;
      font-weight: 800 !important;
    }
    .bgWhite {
      background-color: #f5f5f5 !important;
      border-radius: 10px;
      .branchInfo {
        font-weight: 700;
      }
      .branchInput {
        min-width: 100%;
        background-color: #f5f5f5 !important;
        border: 2px solid #808080;
        font-size: 0.8rem;
        font-weight: 600 !important;
        &:focus {
          box-shadow: none !important;
        }
      }
      .addBranchInput {
        min-width: 100%;
        text-align: center!important;
        background-color: white !important;
        border: 2px solid #808080;
        font-size: 0.8rem;
        font-weight: 600 !important;
      }
    }
    .saveBtn {
      background-color: $color_main !important;
      font-weight: 800 !important;
    }
  }
}

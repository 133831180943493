.giftContent .welcomeContent {
    background-color: #ececec !important;
    border-radius: 10px;
  }
  .giftContent .welcomeContent .registerBtn {
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 15px;
  }

  .giftContent .welcomeContent .noteContent .giftIcons {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  .giftContent .welcomeContent .bgWhite {
    border-radius: 15px;
    background-color: #ffffff;
  }

  .giftContent .welcomeContent .bgWhite .bgGreen table * {
    border: none !important;
  }
  .giftContent .welcomeContent .bgWhite .bgGreen .firstRow {
    color: white;
    font-weight: 800;
  }
  .giftContent .welcomeContent .bgWhite .bgGreen .firstRow .txtRight {
    text-align: center;
  }
  .giftContent .welcomeContent .bgWhite .bgGreen h6 {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 500 !important;
  }
  .giftContent .welcomeContent .bgWhite h6 {
    font-weight: 700 !important;
    color: #808080;
  }

  .giftContent .welcomeContent .bgWhite h4 {
    font-size: 0.8rem;
    font-weight: 700 !important;
    color: #808080;
    margin-bottom: 20px;
  }

  .giftContent .welcomeContent .bgWhite .discountInput {
    font-weight: 800;
    color: #808080;
    text-align: right;
  }
  .giftContent .welcomeContent .bgWhite .inputDiscount {
    background-color: transparent;
    font-weight: 700;
    border: none;
  }
  .giftContent .welcomeContent .bgWhite .discountValue {
    outline: none;
    border: none;
    width: 90px;
    max-width: 90px;
    background-color: #ececec;
    border-radius: 15px !important;
    text-align: center;
    font-weight: 800;
    font-size: 2rem;
  }
  .giftContent .welcomeContent .bgWhite .discountValue:focus {
    box-shadow: none;
  }
  .giftContent .welcomeContent .bgWhite .dicountLabel {
    font-weight: 700;
    font-size: 0.9rem;
  }
  .giftContent .welcomeContent .bgWhite .dicountLabel h6 {
    font-weight: 800 !important;
    font-size: 1rem;
  }

  
  .firstRow > td > span:last-child {
    font-weight: 400;
    font-size: 0.8rem;
  }
  
  .firstRow > td > span:first-child {
    font-size: 1.1rem;
  }
  
  @media (max-width: 767px) {
    .bgWhite .dicountLabel {
      font-weight: 700 !important;
      font-size: 0.7rem !important;
    }
    .bgWhite .dicountLabel h6 {
      font-weight: 800 !important;
      font-size: 0.8rem;
    }
  }
@use "../../../../../vars/colors" as *;

div:has(> .bgModalGrey) {
  background-color: $color_modalBg;
  color: $color_secondary;
  border-radius: 15px;
}

.bgModal {
  .bgModalGrey {
    .closeIcon{
        text-align: right;
        font-size: 30px;
        line-height: 30px;
        color: #d32121;
        svg{
            cursor: pointer;
        }
    }
    .alertIcon {
      width: 100%;
      max-width: 60px;
      height: auto;
    }
    p {
      font-weight: 600;
    }
    .delBtn {
      background-color: #d32121;
      border-radius: 10px;
      font-weight: 700;
      font-size: 0.8rem;
      padding: 5px;
      min-width: 200px;
      color: white;
    }
  }
}

.throwPicContent {
  text-align: center;
}
.throwPicContent .throwPic {
  width: 100%;
  max-width: 300px;
  height: auto;
  text-align: center;
}

.registerContent {
  background-color: #ececec;
  border-radius: 15px;
}
.registerContent .inputGroup {
  border-radius: 35px;
  background-color: #ffffff;
}
.registerContent .inputGroup .inputField {
  background-color: #ececec;
  border-radius: 20px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600 !important;
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}
.registerContent .inputGroup .inputField:focus {
  box-shadow: none !important;
  outline: none;
  border: none;
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}
.registerContent .inputGroup .inputLabel {
  font-weight: 700;
  font-size: 0.9rem !important;
}
.registerContent .inputGroup .inputLabell {
  font-weight: 700;
  font-size: 0.8rem !important;
}
.registerContent .inputGroup .customerInput {
  min-width: 100%;
  text-align: center !important;
  background-color: white !important;
  border: 2px solid #808080;
  font-size: 0.8rem;
  font-weight: 600 !important;
}

.genderLabel {
  border-radius: 20px;
  background-color: #ececec;
  border: none;
  color: #ffffff;
  display: flex;
  min-width: 70px;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  font-weight: 800;
  cursor: pointer;
}
.genderLabel .genderIcon {
  width: 20px;
  height: 20px;
}

.genderInput:checked + label {
  background-color: #ffffff;
  color: gray;
  border: 2px solid gray;
}

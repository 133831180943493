@use "../../../vars/colors" as *;

.Home {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: whitesmoke;
  .navigation {
    position: fixed;
    background-color: $color_main;
    inset: 5px;
    width: 80px;
    overflow: hidden;
    border-left: 10px solid $color_main;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.05);
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    ul {
      padding: 0px;
      list-style-type: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      li {
        margin-top: 15px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        &:hover:not(:first-child) a {
          color: #f5f5f5;
          font-weight: 600 !important;
        }
        a {
          text-decoration: none;
          color: white;
          display: flex;
          .icon {
            display: block;
            min-width: 60px;
            line-height: 50px;
            height: 60px;
            text-align: center;
            font-size: 1.5rem;
            &:hover{
              background-color: $color_background !important;
              border-radius: 15px;
            }
          }
          .icon-merchant-logo {
            display: block;
            min-width: 60px;
            line-height: 50px;
            height: 60px;
            text-align: center;
            font-size: 1.5rem;
          }
          .title {
            display: block;
            padding: 0 10px;
            height: 60px;
            line-height: 65px;
          }
        }
      }
    }
  }
  .active {
    width: 250px;
  }
  .logout {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 25px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-color: $color_background;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    outline: none;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: $color_main
    }
    .logout-icon {
      font-size: 25px;
      color: ghostwhite;
    }
  }
  .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 75px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-color: rgb(198, 117, 5, 0.2);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    .menu-icon {
      font-size: 25px !important;
      color: ghostwhite;
    }
  }
}

.merchant-logo {
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}

.main-page {
  overflow: overlay;
  position: absolute;
  top: 10px;
  left: 110px;
  width: calc(100% - 110px);
  height: calc(100vh - 10px);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  background-color: #ededed;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.main-active {
  left: 275px;
  width: calc(100% - 275px);
}

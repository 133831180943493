@use "../../vars/colors" as *;

.notFoundContent {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(191, 26, 22);
  background: linear-gradient(
    35deg,
    rgba(191, 26, 22, 1) 0%,
    rgba(246, 224, 223, 1) 48%,
    rgba(255, 255, 255, 1) 100%
  );
  .bgGrey {
    background-color: #df4d53;
    border-radius: 20px;
    h4 {
      font-weight: 700;
    }
    p {
      font-weight: 700;
    }
    .sadFace {
      width: 100%;
      max-width: 150px;
      height: auto;
    }
  }
}


div:has(> .modaBodyNewUser){
    background-image: url("../../../imgs/BG Gradient.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}
.modaBodyNewUser{
    .greenLogo{
        width: 100%;
        max-width: 150px;
        height: auto;
    }
    .notesList{
        list-style-type: decimal;
        li{
            padding: 10px;
            font-weight: 700;
            font-size: 1.2rem;
        }
    }
}
@use "../../../vars/colors" as *;

.stores {
  .merchantTitle {
    font-weight: 700 !important;
  }
  .storeContent {
    background-color: #ffffff;
    border-radius: 10px;
    min-height: calc(100vh - 200px);
    h4 {
      font-weight: 700 !important;
    }
    .storeBtnCotentRow {
      min-height: calc(100vh - 300px);
      display: flex;
      justify-content: center;
      align-items: center;
      .storeBtnContent {
        width: 100%;
        min-width: 130px;
        min-height: 120px;
        background-color: $color_main;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 15px;
        cursor: pointer;
        .storeIcon {
          img {
            width: 100%;
            max-width: 60px;
            height: auto;
          }
        }
        .storeTitleBtn {
          font-weight: 800;
          font-size: 1.3rem;
        }
      }
    }
  }
}

@use "../../../vars/colors" as *;

.dashboard {
  .dashboardTitle {
    font-weight: 800 !important;
  }
  .merchantTitle {
    font-weight: 700 !important;
  }
  .bankContent {
    background-color: #ffffff;
    border-radius: 10px;
    min-height: calc(100vh - 200px);
    h4{
      font-weight: 700!important;
    }
    .qrBtn {
      background-color: #ffffff;
      border: 2px solid $color_main;
      color: $color_main;
      font-weight: 700;
      font-size: 1.2rem;
      & > svg {
        color: $color_main;
        font-size: 1.3rem;
      }
    }
    .homeBtnCotentRow {
      min-height: calc(100vh - 300px);
      display: flex;
      justify-content: center;
      align-items: center;
      .homeBtnContent {
        width: 100%;
        min-width: 130px;
        min-height: 120px;
        background-color: $color_main;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 15px;
        cursor: pointer;
        .homeIcon{
          & > svg{
            font-size: 3rem;
          }
        }
        .homeTitleBtn{
          font-weight: 800;
          font-size: 1.3rem;
        }
      }
    }
  }
}

div:has(> .reportBtn) {
  text-align: end;
}

div:has(> .qrBtn) {
  text-align: right;
}

.layoutContent .langContent {
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
}
.layoutContent .layoutHeader {
  padding-top: 20px;
  min-height: 100px;
  text-align: center;
}
.layoutContent .layoutHeader .greenLogo {
  width: 200px;
  height: auto;
}
.layoutContent .layoutBody {
  min-height: calc(100vh - 200px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-SemiBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Regular.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Medium.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "IBMPlexSansArabic";
  src: url("../src/fonts/IBMPlexSansArabic-Bold.ttf");
  font-weight: 800;
}

* {
  font-family: "Montserrat", "IBMPlexSansArabic" !important;
}

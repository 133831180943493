

.scanModal {
  video {
    width: 100%;
  }
  canvas {
    position: absolute;
  }
}

.bgModalGrey{
    h4{
        font-weight: 700!important;
        font-size: 1.2rem;
    }
    .scanCodeReuslt{
      background-color: #ededed;
      font-weight: 700;
      font-size: 1rem;
      border-radius: 10px;
      text-align: center;
    }
}

@use "../../../../vars/colors" as *;

div:has(> .bgModalGrey) {
  background-color: $color_modalBg;
  border-radius: 15px;
}
.bgModal {
  background-color: rgb(0, 0, 0, 0.8);
  .bgModalGrey {
    .bgWhite {
      background-color: #f5f5f5 !important;
      border-radius: 10px;
      h5{
        font-weight: 700;
        font-size: 0.9rem;
      }
      h2{
        font-weight: 900;
        font-size: 3rem!important;
      }
    }
    h4 {
      font-size: 1.4rem !important;
      font-weight: 800 !important;
    }
    .downloadQrBtn {
      background-color: $color_main !important;
      font-weight: 800 !important;
    }
  }
}

.throwPicContent {
  text-align: center;
}
.throwPicContent .throwPic {
  width: 100%;
  max-width: 300px;
  height: auto;
  text-align: center;
}

.congratsContent {
  background-color: #ececec;
  border-radius: 15px;
}
.congratsContent .bgWhite {
  background-color: #ffffff;
  border-radius: 10px;
}
.congratsContent .bgWhite .pointsIcon {
  width: 60px;
  height: auto;
}
.congratsContent .bgWhite h4 {
  font-weight: 900;
  font-style: italic;
  color: #000000;
  font-size: 1.3rem;
}
.congratsContent .bgWhite .giftCodeContent {
  background-color: #ffffff;
}
.congratsContent .bgWhite .giftCodeContent .giftCode {
  width: 100%;
  max-width: 140px;
  height: auto;
  padding: 10px 15px;
  border: 2px solid gray;
  border-radius: 10px;
}
.congratsContent .bgWhite .validCode {
  font-size: 0.75rem;
  color: #808080;
  font-weight: 700;
}

.congratsContent .bgWhite h5 {
  font-weight: 900;
  color: #000000;
  font-size: 1.1rem;
}

.throwPicContent {
  text-align: center;
}
.throwPicContent .throwPic {
  width: 100%;
  max-width: 300px;
  height: auto;
  text-align: center;
}

.welcomeContent {
  background-color: #ececec;
  border-radius: 15px;
}
.welcomeContent .inputGroup {
  border-radius: 10px;
  background-color: #ffffff;
}
.welcomeContent .inputGroup .inputField {
  background-color: #ececec;
  border-radius: 20px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600 !important;
}
.welcomeContent .inputGroup .inputField:focus {
  box-shadow: none !important;
  outline: none;
  border: none;
}
.welcomeContent .inputGroup .inputLabel {
  font-weight: 700;
  font-size: 0.9rem !important;
}
.welcomeContent .proceedBtn {
  background-color: #22af68;
  border-radius: 20px;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 10px 25px;
}

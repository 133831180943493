@use "../../../../vars/colors" as *;

.branchsHeader {
  background: rgb(131, 11, 10);
  background: linear-gradient(
    35deg,
    rgba(131, 11, 10, 1) 0%,
    rgba(191, 26, 22, 1) 100%
  );
  color: #ffffff;
  font-style: italic;
  font-weight: 800 !important;
}
.branchsBody {
  background-color: #dcdcdc;
  .addBranchBtn {
    background-color: $color_main;
    font-weight: 700 !important;
    &:hover {
      background-color: $color_secondary;
    }
  }
  .bgWhite {
    background-color: #f5f5f5 !important;
    border-radius: 10px;
    .branchName {
      font-weight: 700;
    }
    .branchStyle {
      min-width: 100%;
      background-color: #f5f5f5 !important;
      border: 2px solid #808080;
      font-size: 0.8rem;
      font-weight: 600 !important;
    }
    .branchElement {
      border: 2px solid #808080;
      border-radius: 10px;
      span {
        font-weight: 700;
        font-size: 0.8rem;
      }
    }
  }
}

@use "../../../vars/colors" as *;

.redeemContent {
  min-height: 100vh;
  width: 100%;
  background-color: #ededed;
  h4 {
    font-weight: 800;
    font-size: 1.5rem;
    text-align: left !important;
  }
  .homeBtn {
    background-color: $color_main;
    border-radius: 5px;
    font-weight: 700;
    font-size: 0.9rem;
  }
  .bgWhite {
    background-color: #ffffff;
    border-radius: 15px;
    min-height: 150px;
    h5 {
      font-weight: 800;
      font-size: 1.3rem;
    }
    .giftContent {
      font-size: 1.6rem;
      font-weight: 700;
      text-align: center;
      color: $color_secondary;
      & > span {
        padding: 15px 45px;
        border: 3px dashed $color_secondary;
        border-radius: 10px;
        color: $color_secondary;
        font-weight: 900;
        .unit {
          font-size: 0.9rem;
          font-weight: 600;
        }
      }
    }
    .inputGroup {
      border-radius: 10px;
      .bgGrey {
        background-color: #e4e4e4;
        border-radius: 10px;
        .inputField {
          background-color: white;
          border-radius: 10px;
          text-align: center;
          font-size: 0.8rem;
          font-weight: 600 !important;
          &:focus {
            box-shadow: none !important;
            outline: none;
            border: none;
          }
        }
      }
      .inputLabel {
        font-weight: 700;
        font-size: 0.9rem !important;
      }
    }
    .proceedBtn {
      background-color: $color_main;
      border-radius: 20px;
      font-weight: 700;
      font-size: 0.9rem;
      padding: 10px 25px;
    }
    .scanBtn {
      background-color: $color_main;
      border-radius: 20px;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 35px;
    }
  }
}

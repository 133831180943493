@use "../../../../../vars/colors" as *;

input[type="text"] {
  font-family: "Montserrat" !important;
}

.ModalBodyOTP {
  font-family: "IBMPlexSansArabic", "Montserrat" !important;
  font-size: 18px !important;
}

.digitsParent {
  background-color: #e4e4e4;
  border-radius: 20px;
  .otpInput {
    width: 100%;
    border: 2px solid #88c599;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    font-family: "Montserrat", "IBMPlexSansArabic" !important;
  }
}

.nextBtn {
  background-color: $color_main;
  border-radius: 20px;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 10px 25px;
}

div:has(> .otpInput) {
  max-width: 70px;
  min-width: 33px;
  margin: 0px;
  padding: 3px;
}

.errorInput {
  border: 3px solid #ec1c24 !important;
  transition: all 0.2s;
}

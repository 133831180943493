@use "../../vars/colors" as *;

.changeLangs {
  .langBtn {
    border: none;
    border-radius: 10px;
    background-color: $color_main;
    color: white;
    font-size: 12px !important;
    font-weight: 700 !important;
    font-family: "Montserrat","IBMPlexSansArabic"!important;
    &:hover {
      background-color: #127b47 !important;
      color: white !important;
    }
  }
}

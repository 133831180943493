.text-error {
  color: #ec1c24 !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
}

.text-error-green {
  color: #ec1c24 !important;
  font-weight: 700 !important;
  font-size: 0.7rem !important;
}

.Toastify__toast {
  font-family: "Montserrat" !important;
  font-size: 0.8rem !important;
  font-weight: 800 !important;
  font-style: italic !important;
}
.Toastify__toast-theme--light.Toastify__toast--default {
  background: linear-gradient(
    90deg,
    rgba(216, 113, 120, 1) 0%,
    rgba(189, 37, 54, 1) 100%
  );
  color: white !important;
}

.Toastify__toast--rtl {
  font-family: "IBMPlexSansArabic" !important;
  font-size: 0.9rem !important;
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background: linear-gradient(
    90deg,
    rgba(255, 142, 158, 1) 0%,
    rgba(255, 189, 158, 1) 100%
  );

  color: whitesmoke !important;
}
.Toastify__toast-theme--light.Toastify__toast--success {
  background: linear-gradient(
    90deg,
    rgba(156, 220, 120, 1) 0%,
    rgba(120, 220, 173, 1) 100%
  );
  color: whitesmoke !important;
}

.btn-close {
  filter: brightness(0) invert(1);
  opacity: 1;
  margin: 5px!important;
}
.btn-close:focus{
  border: none!important;
  outline: none!important;
  box-shadow: none!important;
}

@use "../../../../../vars/colors" as *;

div:has(> .bgModalGrey2) {
  background-color: $color_modalBg;
  border-radius: 15px;
}
.bgModal2 {
  background-color: rgb(0, 0, 0, 0.8);
  .bgModalGrey2 {
    h4 {
      font-size: 1.4rem !important;
      font-weight: 800 !important;
    }
    .bgWhite2 {
      background-color: #f5f5f5 !important;
      border-radius: 10px;
      .branchName2,
      .cashierInfo2 {
        font-weight: 700;
      }
      .branchStyle2,
      .cashierInput2 {
        min-width: 100%;
        background-color: #f5f5f5 !important;
        border: 2px solid #808080;
        font-size: 0.8rem;
        font-weight: 600 !important;
        &:focus {
          box-shadow: none !important;
        }
      }
    }
    .saveBtn2 {
      background-color: $color_main !important;
      font-weight: 800 !important;
    }
  }
}

@use "../../../../vars/colors" as *;

div:has(> .bgModalGrey) {
    background-color: $color_modalBg;
    border-radius: 15px;
  }
  .bgModal {
    background-color: rgb(0, 0, 0, 0.8);
    .bgModalGrey {
      h4 {
        font-size: 1.4rem !important;
        font-weight: 800 !important;
      }
      p{
        font-weight: 700;
        text-align: center;
        font-size: 0.9rem;
      }
      .qrImage{
        width: 100%;
        max-width: 160px;
        height: auto;
      }
      .downloadQrBtn{
        background-color: $color_main !important;
        font-weight: 800 !important;
      }
    }
  }
  